
import Vue from 'vue'
import Header from '~/components/layouts/Header.vue'
import Footer from '~/components/layouts/Footer.vue'
import NonMemberModal from '~/components/shared/NonMemberModal.vue'
import Modal from '~/components/shared/Modal.vue'
import LogoBtn from '~/components/shared/LogoBtn.vue'
import Logo from '~/components/shared/Logo.vue'

export default Vue.extend({
  name: 'Blank',
  computed: {
    modal() {
      return this.$store.state.modal.nonMemberModalState
    }
  },
  methods: {
    modalClose() {
      this.$store.dispatch('modal/changeNonMemberModalState', false)
    },
    modalOpen() {
      this.$store.dispatch('modal/changeNonMemberModalState', true)
    },
    clickButton() {
      this.modalClose()
    },
    displayNav() {
      // pages/index.vueのみ表示
      return this.$route.name !== 'index'
    }
  },
  head: {
    htmlAttrs: {
      class: ['reset-overflow']
    }
  }
}).extend({
  components: {
    Header,
    Footer,
    NonMemberModal,
    Modal,
    LogoBtn,
    Logo
  }
})
