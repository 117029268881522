
import Vue from 'vue'
import Header from '~/components/layouts/Header.vue'
import Footer from '~/components/layouts/Footer.vue'
import NonMemberModal from '~/components/shared/NonMemberModal.vue'
import Modal from '~/components/shared/Modal.vue'
import LogoBtn from '~/components/shared/LogoBtn.vue'
import Logo from '~/components/shared/Logo.vue'

export default Vue.extend({
  name: 'Default',
  computed: {
    isReportPath() {
      const path = this.$route.path
      return path.indexOf('/reports/') > 0
    },
    bottomFixedButton() {
      const page = [
        'companyId',
        'companyId-stories-id',
        'companyId-knowhows-id',
        'members'
      ]
      return page.includes(this.$route.name)
    },
    bookshelfPath() {
      const pathName = this.$route.name
      return pathName === 'members-bookshelf'
    },
    modal() {
      return this.$store.state.modal.nonMemberModalState
    },
    displayNav() {
      // pages/index.vueのみ表示
      return this.$route.name !== 'index'
    }
  },
  mounted() {
    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight * 0.01}px`
    )
  },
  methods: {
    isPreviewPath() {
      return /^\/preview\//.test(this.$route.path)
    },
    modalClose() {
      this.$store.dispatch('modal/changeNonMemberModalState', false)
    },
    modalOpen() {
      this.$store.dispatch('modal/changeNonMemberModalState', true)
    },
    clickButton() {
      this.modalClose()
    }
  },
  head: {
    htmlAttrs: {
      class: ['reset-overflow']
    }
  }
}).extend({
  components: {
    Header,
    Footer,
    NonMemberModal,
    Modal,
    LogoBtn,
    Logo
  }
})
