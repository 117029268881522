import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'

// APIコールが完了する前に、別APIコールした際に直前のAPIコールをキャンセルするためのToken定義
const CancelToken = axios.CancelToken
let cancel: any

export const state = () => ({
  showLoading: false,
  noResults: false,
  articleRecods: [],
  articlesPageMeta: [],
  searchStories: [],
  selectedTagStories: {},
  storyIdArticleContent: {},
  storyIdArticleKeywords: '',
  topContents: {}
})

export const mutations = {
  SET_SELECTED_TAG__STORIES(state, stories) {
    state.selectedTagStories = stories
  },
  SET_STORYIES(state, article) {
    state.articleRecods = article
    state.showLoading = true
  },
  SET_PAGE_META(state, pageMeta) {
    state.articlesPageMeta = pageMeta
  },
  CHANGE_SHOW_LOADING(state, showBoolean) {
    state.showLoading = showBoolean
  },
  SET_CATEGORY_FILTER(state, result) {
    if (result.records) {
      state.articleRecods = result.records
      state.articlesPageMeta = result.pageMeta
    } else {
      state.articleRecods = result
      state.articlesPageMeta = result
    }
  },
  SET_NO_RESULTS(state, showBoolean: boolean) {
    state.noResults = showBoolean
  },
  SET_ARTICLE_CONTENT(state, res: any) {
    state.storyIdArticleContent = res
    state.storyIdArticleKeywords = res.tagArray.join(',')
  },
  SET_TOP_CONTENTS(state, res: any) {
    state.topContents = res
  }
}

export const actions = {
  getTopContents({ commit }, res) {
    commit('SET_TOP_CONTENTS', res)
  },
  getStoryIdArticleContent(context, res: any) {
    context.commit('SET_ARTICLE_CONTENT', res)
    // store/bookshelf.tsのmutationにcommit
    context.commit('bookshelf/SET_IS_SAVED', res.isSaved, { root: true })
    context.commit('bookshelf/SET_COMPLITE_IS_SAVED', true, { root: true })
  },
  getSelectedTagStories({ commit }, res: any) {
    commit('SET_SELECTED_TAG__STORIES', res)
  },
  async getStories(context, payload: any) {
    if (cancel) {
      cancel('getStoriesNewRequest')
    }
    const source = CancelToken.source()
    const { commit } = context
    cancel = source.cancel
    commit('CHANGE_SHOW_LOADING', true)
    const config = {
      auth: authSetting,
      withCredentials: true,
      cancelToken: source.token
    }
    const categoryIdString = payload.route.query.category
    const categoryIdArray = categoryIdString ? categoryIdString.split(',') : []
    function categoryIdsStr(str) {
      return categoryIdArray.length
        ? categoryIdArray
            .map((v: any, i: number) =>
              !i ? str + `category_ids[]=` + v : `&category_ids[]=` + v
            )
            .join('')
        : ''
    }
    const page = payload.route.query.page
      ? `?page=${payload.route.query.page}`
      : ''
    const baseUrl = process.env.API_URL + '/contents'
    const baseStoriesUrl = process.env.API_URL + '/stories'
    const baseKnowhowsUrl = process.env.API_URL + '/knowhows'
    const contentsUrl = page
      ? baseUrl + page + categoryIdsStr('&')
      : baseUrl + categoryIdsStr('?')
    const storiesUrl = page
      ? baseStoriesUrl + page + categoryIdsStr('&')
      : baseStoriesUrl + categoryIdsStr('?')
    const knowhowsUrl = page
      ? baseKnowhowsUrl + page + categoryIdsStr('&')
      : baseKnowhowsUrl + categoryIdsStr('?')
    const followUrl = page
      ? baseUrl + page + '&is_following=true' + categoryIdsStr('&')
      : baseUrl + '?is_following=true' + categoryIdsStr('&')
    // const recommendUrl = page
    //   ? baseStoryUrl + page + '&recommend=true' + categoryIdsStr('&')
    //   : baseStoryUrl + '?recommend=true' + categoryIdsStr('&')

    if (payload.route.query.filter === 'follow') {
      await getAPI(followUrl)
    } else if (payload.route.query.filter === 'stories') {
      await getAPI(storiesUrl)
    } else if (payload.route.query.filter === 'knowhows') {
      await getAPI(knowhowsUrl)
    } else if (payload.route.query.filter === 'recommend') {
      // ▼ APIできるまでの仮▼
      const url = process.env.API_URL + '/recommended_stories'
      const pageMeta = {
        currentCursor_end: 1,
        currentCursor_start: 1,
        currentPage: 1,
        totalContent: 1,
        totalPages: 1
      }
      // ▲ APIできるまでの仮▲
      await this.$axios
        .$get(url, config)
        .then((res) => {
          commit('SET_STORYIES', camelcaseKeys(res, { deep: true }))
          commit('SET_PAGE_META', pageMeta)
          // ▼ APIができたらよしなに差し替え▼
          // commit('SET_STORYIES', camelcaseKeys(res.records, { deep: true }))
          // commit('SET_PAGE_META', camelcaseKeys(res.page_meta, { deep: true }))
          // ▲ APIができたらよしなに差し替え▲
          commit('SET_NO_RESULTS', false)
          commit('CHANGE_SHOW_LOADING', false)
        })
        .catch((err) => {
          if (!(err.message === 'getStoriesNewRequest')) {
            commit('SET_NO_RESULTS', true)
            commit('CHANGE_SHOW_LOADING', false)
          }
          return err
        })
    } else {
      await getAPI(contentsUrl)
    }
    async function getAPI(url) {
      const config = {
        headers: {
          'Auth-Token-Api': process.env.AUTH_TOKEN_API_HEADERS
        },
        auth: authSetting,
        withCredentials: true,
        cancelToken: source.token
      }
      try {
        // this.$axiosが参照できないためimportしたaxiosを参照
        const res = await axios.get(url, config)
        commit('SET_STORYIES', camelcaseKeys(res.data.records, { deep: true }))
        commit(
          'SET_PAGE_META',
          camelcaseKeys(res.data.page_meta, { deep: true })
        )
        commit('SET_NO_RESULTS', false)
        commit('CHANGE_SHOW_LOADING', false)
      } catch (err) {
        if (!(err.message === 'getStoriesNewRequest')) {
          commit('SET_NO_RESULTS', true)
          commit('CHANGE_SHOW_LOADING', false)
        }
        return err
      }
    }
  }
}
