// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/talentbook_color.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal__overlay[data-v-00ab6c88]{align-items:center;background:rgba(0,0,0,.7);display:flex;height:100%;justify-content:center;left:0;position:fixed;top:0;width:100%;z-index:100}.modal__window[data-v-00ab6c88]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 100% no-repeat;background-blend-mode:lighten;background-color:hsla(0,0%,100%,.6);background-position:45% 0;background-size:cover;box-shadow:none;height:65%;max-height:90vh;max-width:480px;overflow:scroll;position:relative;width:70%}@media screen and (max-width:767px){.modal__window[data-v-00ab6c88]{height:100%;max-height:100%;max-width:100%;width:100%}}.modal__content[data-v-00ab6c88]{height:auto;padding:30px}.button-close[data-v-00ab6c88]{cursor:pointer;display:block;height:20px;position:absolute;right:20px;top:20px;width:20px;z-index:5}.button-close[data-v-00ab6c88]:after,.button-close[data-v-00ab6c88]:before{background:#000;content:\"\";display:block;height:2px;left:50%;position:absolute;top:50%;width:100%}.button-close[data-v-00ab6c88]:before{transform:translate(-50%,-50%) rotate(45deg)}.button-close[data-v-00ab6c88]:after{transform:translate(-50%,-50%) rotate(-45deg)}.fade-enter-active[data-v-00ab6c88],.fade-leave-active[data-v-00ab6c88]{transition:opacity .5s}.fade-enter[data-v-00ab6c88],.fade-leave-to[data-v-00ab6c88]{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
