export default {
  methods: {
    scrollL(num: number, len: number) {
      if (num) {
        const div = this.$refs.tagContainer[len]
        div.scrollLeft -= div.clientWidth / 2
      } else {
        const div = this.$refs.tagContainer
        div.scrollLeft -= div.clientWidth / 2
      }
    },
    scrollR(num: number, len: number) {
      if (num) {
        const div = this.$refs.tagContainer[len]
        div.scrollLeft += div.clientWidth / 2
      } else {
        const div = this.$refs.tagContainer
        div.scrollLeft += div.clientWidth / 2
      }
    }
  }
}
