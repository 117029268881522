
import { Component, Vue } from 'vue-property-decorator'
import Header from '~/components/layouts/Header.vue'
import SideNav from '~/components/layouts/SideNav.vue'
import Footer from '~/components/layouts/Footer.vue'

@Component({
  components: {
    Header,
    SideNav,
    Footer
  }
})
export default class mypageLayout extends Vue {}
