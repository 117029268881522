import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'
import { errorHandler } from '~/lib/errorHandling'
import { MypageType } from '~/types/mypage'
import { API } from '~/types/api'

export const state = () => ({
  activateEmail: {},
  defaultGender: [
    {
      name: '男性',
      value: 0
    },
    {
      name: '女性',
      value: 1
    },
    {
      name: 'その他',
      value: 2
    }
  ],
  profile: {},
  disabledButtonProfile: true,
  disabledButtonInterests: true,
  disabledButtonNotification: true,
  changePasswordState: false,
  quitMessage: false
})

export const mutations = {
  SET_PROFILE(state, profile: MypageType.Profile) {
    state.profile = profile
  },
  SET_NEW_PROFILE(state, profile: MypageType.UpdateProfile) {
    state.profile.gender = profile.gender
    state.profile.birthday = profile.birthday
    state.profile.job = profile.job
    state.profile.graduate = profile.graduate
    state.profile.purpose = profile.purpose
  },
  SET_BUTTON_STATE_PROFILE(state, valid: boolean) {
    state.disabledButtonProfile = valid
  },
  SET_BUTTON_STATE_INTERESTS(state, valid: boolean) {
    state.disabledButtonInterests = valid
  },
  SET_BUTTON_STATE_NOTIFICATION(state, valid: boolean) {
    state.disabledButtonNotification = valid
  },
  SET_ACTIVATE(state, email: string) {
    state.activateEmail = email
  },
  SET_CHANGE_PASSWORD_STATE(state, bool: boolean) {
    state.changePasswordState = bool
  },
  SET_QUIT_MESSAGE(state, bool: boolean) {
    state.quitMessage = bool
  }
}

export const actions = {
  gotProfile({ commit }, profile: API.Profile) {
    commit('SET_PROFILE', camelcaseKeys(profile, { deep: true }))
  },
  updateProfile({ commit }, profile: MypageType.UpdateProfile) {
    commit('SET_NEW_PROFILE', profile)
  },
  disabledButtonProfile({ commit }, valid: boolean) {
    commit('SET_BUTTON_STATE_PROFILE', valid)
  },
  disabledButtonInterests({ commit }, valid: boolean) {
    commit('SET_BUTTON_STATE_INTERESTS', valid)
  },
  disabledButtonNotification({ commit }, valid: boolean) {
    commit('SET_BUTTON_STATE_NOTIFICATION', valid)
  },
  async getActivate({ commit }, payload: any) {
    const url = process.env.API_URL + '/members/activate/' + payload.params
    await this.$axios
      .$get(url, { auth: authSetting })
      .then((res: any) => {
        commit('SET_ACTIVATE', res)
      })
      .catch((err) => {
        errorHandler(err, payload.context)
        return err
      })
  },
  updateChangePasswordState({ commit }, bool: boolean) {
    commit('SET_CHANGE_PASSWORD_STATE', bool)
  },
  updateQuitMessage({ commit }, bool: boolean) {
    commit('SET_QUIT_MESSAGE', bool)
  }
}
