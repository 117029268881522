
import { Component, Vue } from 'vue-property-decorator'
import tagScroll from '~/components/mixins/MixinTagScroll'
import { Tags } from '~/types/tags'

@Component({
  async fetch() {
    await this.$store.dispatch('tags/getAllTags', this.$nuxt.context)
  },
  mixins: [tagScroll]
})
export default class CategoryNav extends Vue {
  private swiperOption = {
    spaceBetween: 16,
    slidesPerView: 'auto',
    loop: false,
    speed: 1000,
    watchSlidesVisibility: true,
    observeParents: true,
    observer: true,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next'
    }
  }

  private menuStates = []
  private isMobileDevice: boolean = false

  private mounted() {
    const categoryLen = this.displayCategoryData.length - 1
    this.closeAllMenus()
    for (let i = 0; i < categoryLen; i++) {
      this.menuStates.push(false)
    }
  }

  private checkDeviceType() {
    const responsiveSize = 767
    this.isMobileDevice = window.innerWidth <= responsiveSize
  }

  private toggleMenu(index: number) {
    // このメニューがすでに開いているか確認
    if (this.menuStates[index]) {
      this.$set(this.menuStates, index, false)
    } else {
      this.closeAllMenus()
      this.$set(this.menuStates, index, true)
    }
    // スワイプの有効/無効を設定
    this.updateSwiper()
  }

  // すべてのメニューを閉じる
  private closeAllMenus() {
    this.menuStates = this.menuStates.map(() => false)
  }

  // menuの開閉状況に応じてスワイプ操作(有効/無効)の切り替え
  private updateSwiper() {
    const swiperContents = (this as any).$refs.categorySlide.$swiper
    const allMenusClosed = this.menuStates.every((v: any) => v === false)
    if (swiperContents) {
      swiperContents.allowTouchMove = allMenusClosed
    }
  }

  // メニューが閉じられた場合、スワイプを有効にする
  private handleMenuInput(index: number, value: boolean) {
    if (!value) {
      // menuの状態をリアクティブに保持するため$setで変更
      this.$set(this.menuStates, index, value)
      this.updateSwiper()
    }
  }

  private get categories(): Tags.Categorization {
    return this.$store.state.tags.categorization
  }

  private get displayCategoryData() {
    const categoryList = JSON.parse(JSON.stringify(this.categories))
    // swiperの最終要素が見切れてしまうので回避策としてhiddenDataをpush
    const hiddenData = { categoryName: '' }
    categoryList.push(hiddenData)
    return categoryList
  }

  private get displayNav() {
    // indexページのみcategoryNavを表示
    const isIndexPage = this.$route.path === '/'

    return isIndexPage
  }
}
