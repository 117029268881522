
import { Component, Vue } from 'vue-property-decorator'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import UniversalCookie from 'universal-cookie'
import HeaderLogo from '~/components/layouts/header/HeaderLogo.vue'
import GlobalMenu from '~/components/layouts/header/menu/GlobalMenu.vue'
import Modal from '~/components/shared/Modal.vue'
import LogoBtn from '~/components/shared/LogoBtn.vue'
import CategoryNav from '~/components/layouts/CategoryNav.vue'
import SideNav from '~/components/layouts/SideNav.vue'
import authSetting from '~/config/authSetting'
import MixinMemberId from '~/components/mixins/MixinMemberId'
import MixinLogout from '~/components/mixins/MixinLogout'
import { API } from '~/types/api'

@Component({
  components: {
    HeaderLogo,
    GlobalMenu,
    Modal,
    LogoBtn,
    SideNav,
    CategoryNav
  },
  async fetch() {
    await this.$store.dispatch(
      'time/getServerTimestamp',
      this.$fetchState.timestamp
    )
  },
  watch: {
    '$route.path'(newPath, oldPath) {
      this.changePath(newPath, oldPath)
    }
  },
  mixins: [MixinMemberId, MixinLogout]
})
export default class Header extends Vue {
  private memberId!: number
  private menuActive = false
  private searchWord = ''
  private showSearch = false

  private get auth() {
    return this.$store.state.authentication.membersHeader
  }

  private submitSearch() {
    if (this.searchWord.trim()) {
      // URLにqueryパラメータを付けて遷移
      const encodedQuery = encodeURIComponent(this.searchWord.trim())
      const searchUrl = `/members/search?query=${encodedQuery}`
      // 検索inputをクリア
      this.searchWord = ''
      this.showSearch = false
      this.$router.push(searchUrl)
    }
  }

  private async changePath(newPath, oldPath) {
    if (newPath !== oldPath) {
      await this.$store.dispatch(
        'notifications/getNotifications',
        this.memberId
      )
    }
  }

  private clickNotification(linkTo) {
    this.menuActive = false
    this.$router.push(linkTo)
  }

  private get confirmPath() {
    return this.$route.path === '/'
  }

  private get modal() {
    return this.$store.state.search.searchModalState
  }

  // ----------- tab ---------------

  private tab = null

  private defaultTab() {
    // tabの初期位置設定
    this.tab = this.$store.state.notifications.activeTab
    setTimeout(() => this.submitCall(), 500)
  }

  private get notifications() {
    return this.$store.state.notifications.notifications
  }

  private changeTab() {
    this.tab === 0 ? (this.tab = 1) : (this.tab = 0)
    this.submitCall()
  }

  private submitCall() {
    const notices = this.tab === 0 && !this.notifications.notices.confirmed
    const info = this.tab === 1 && !this.notifications.info.confirmed
    if (notices || info) this.submit()
  }

  // ----------- POST ---------------
  private async submit() {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.submitAction(token.csrf_token)
        })
    } catch (err) {
      return err
    }
  }

  private async submitAction(csrf: string) {
    const cookies = new UniversalCookie()
    const url = process.env.API_URL + `/members/${this.memberId}/notification`
    const postData = {
      notification_type: this.tab
    }
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios.$post(url, postData, config).then(() => {
        this.$store.dispatch(
          'notifications/updateReadAt',
          postData.notification_type
        )
      })
    } catch (err) {
      // APIレスポンスが401だった場合
      const unauthorized =
        err.response.status === 401 || err.response.status === 404
      if (unauthorized) {
        cookies.set('unauthorized_error', true)
        this.errorLogout()
      }
      return err
    }
  }

  private async errorLogout() {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.errorLogoutAction(token.csrf_token)
        })
    } catch (e) {
      return e
    }
  }

  private async errorLogoutAction(csrf: string) {
    const url = process.env.API_URL + '/members/logout'
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios
        .$patch(url, {}, config)
        .then(() => {
          // sessionStorageのmemberId削除
          sessionStorage.removeItem('member')
        })
        .then(() => {
          const redirectUrl = `/`
          location.href = redirectUrl
        })
    } catch (e) {
      return e
    }
  }

  // ----------- user menu ---------------
  private memberMenu = {
    menu: [
      {
        name: 'マイ本棚',
        link: '/members/bookshelf'
      },
      {
        name: '最近見たページ',
        link: '/members/history'
      }
    ],
    settings: [
      {
        name: 'プロフィール',
        param: 'profile'
      },
      {
        name: '通知',
        param: 'notification'
      }
    ],
    other: [
      {
        name: 'よくある質問',
        link: '/faq'
      }
    ]
  }

  private memberMenuOpend = false
  private headerMenuOpend = false

  private linkToParam(param) {
    const url = `/members/profile?type=${param}`
    this.$router.push(url)
  }

  private async logout() {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.logoutAction(token.csrf_token)
        })
    } catch (e) {
      return e
    }
  }

  private get spMenu() {
    return this.$store.state.spmenu.spMenuActive
  }

  private selectMenu() {
    this.$store.dispatch('spmenu/setMenuActive', this.spMenu)
    const spmenu = document.querySelector('.nav-menu')
    if (this.spMenu) {
      disableBodyScroll(spmenu)
      spmenu.scrollTop = 0
    } else {
      clearAllBodyScrollLocks()
    }
  }

  private async logoutAction(csrf: string) {
    const cookies = new UniversalCookie()
    const url = process.env.API_URL + '/members/logout'
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios
        .$patch(url, {}, config)
        .then(() => {
          // API401の際にsetされるcookie削除
          cookies.remove('unauthorized_error')
          // 退会完了時にsetされるcookie削除
          cookies.remove('quitMessage')
          // sessionStorageのmemberId削除
          sessionStorage.removeItem('member')
        })
        .then(() => {
          const redirectUrl = `/login?logout=true`
          location.href = redirectUrl
        })
    } catch (e) {
      return e
    }
  }

  private openNonMemberModal() {
    this.$store.dispatch('modal/changeNonMemberModalState', true)
  }

  private handleCloseMenu() {
    this.memberMenuOpend = false
    this.headerMenuOpend = false
    this.$forceUpdate()
  }
}
